import './App.css';
import { MdAppSettingsAlt } from "react-icons/md";
import { MdInvertColors } from "react-icons/md";
import { FaCarCrash } from "react-icons/fa";
import { RiSteering2Fill } from "react-icons/ri";
import { GiLockpicks } from "react-icons/gi";
import { MdFullscreen } from "react-icons/md";
import { useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { es } from 'date-fns/locale/es';
registerLocale('es', es);

function App() {

  const [startDate, setStartDate] = useState(new Date());
  const [data, setData] = useState({
    date: new Date(),
    name: '',
    phone: ''
  })

  function handleChange(e) {
    const { name, value } = e.target;
    setData(data => ({ ...data, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch('https://diagnosticar.cl/agendar.php', {
      method: 'POST',
      body: JSON.stringify({...data, date: new Date(data.date).toLocaleString('es-ES',
        {
          weekday: 'long', day: 'numeric', month: 'long'
        }
      )}),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response.ok) {
      try {
        const jsonResponse = await response.json();
        if(jsonResponse.status) {
          alert("Agendado correctamente");
          setData({
            date: new Date(),
            name: '',
            phone: ''
          })
        }
        else
          alert("Error")  
      } catch (error) {
        alert("Error")  
      }
    } else {
      alert("Error")  
    }
  }

  return (
    <>
      <header>
        <div class="header">
          <div class="container">
            <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col logo_section">
                <div class="full">
                  <div class="center-desk">
                    <div class="logo">
                      <a href="index.html"><img src="images/logo.png" alt="#" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                <nav class="navigation navbar navbar-expand-md navbar-dark ">
                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarsExample04">
                    <ul class="navbar-nav mr-auto">
                      <li class="nav-item">
                        <a class="nav-link" href="index.html"> Inicio  </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#nosotros">Nosotros</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#servicios"> Servicios  </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#funcionamiento"> Funcionamiento  </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#contacto">Contacto</a>
                      </li>
                    </ul>
                    <div class="sign_btn"><a href="#agenda">Agendar</a></div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section class="banner_main">
        <div class="container">
          <div class="row d_flex">
            <div class="col-md-8">
              <div class="text-bg">
                <div>
                  <h1>Revisión pre-compra de vehiculos</h1>
                  <span>Hasta la puerta de tu casa &#128521; <strong style={{color: "white"}}>!</strong></span>
                  <a href="#agenda">Agendar</a>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="text-img">
                <figure><img src="images/toy_img.png" alt="#" /></figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="agenda" class="form-container">
        <h1 style={{marginBottom: "2rem"}}>Agendar Cita</h1>
        <form onSubmit={handleSubmit}>
            <div class="form-grid">
                <div class="form-group">
                    <label for="nombre">Nombre</label>
                    <input required type="text" value={data.name} onChange={handleChange} name="name" placeholder="Ingresa tu nombre" />
                </div>
                <div class="form-group">
                    <label for="celular">Celular</label>
                    <input required type="text" pattern="\d{11}" maxLength={11} title="Ejemplo: 56987654321" value={data.phone} onChange={handleChange} name="phone" placeholder="Ingresa tu celular" />
                </div>
                <div class="form-group">
                    <label for="date">Fecha</label>
                    <ReactDatePicker required minDate={startDate} dateFormat="dd/MM/yyyy" onChange={(date) => setData(data => ({ ...data, date: date }))} selected={data.date} locale="es" />
                </div>
                <div class="form-group">
                  <label style={{opacity: 0}}>&nsbp;</label>
                  <button type="submit" id="agendar">
                    Agendar
                  </button>
                </div>
                
            </div>
        </form>
      </div>
      <div id="nosotros" class="about">
        <div class="container">
          <div class="row d_flex">
            <div class="col-md-7">
              <div class="titlepage">
                <h2>Sobre Nosotros</h2>
                <p>Nuestra empresa nació de la necesidad de ofrecer una solución confiable y transparente para ayudar a los compradores a tomar decisiones informadas y seguras al adquirir un automóvil usado.</p>
                <p>Nos dedicamos a proporcionar un servicio integral de diagnóstico de automóviles previo a la compra para brindarte tranquilidad y seguridad en tu próxima compra.</p>
              </div>
            </div>
            <div class="col-md-5">
              <div class="about_img">
                <figure><img src="images/about_img.png" alt="#" /></figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg_full">
        <div class="works">
          <div class="container">
            <div id="servicios" class="row">
              <div class="col-md-12 mt-5">
                <div class="titlepage">
                  <h2>Servicios</h2>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 d-flex flex-column justify-content-center align-items-center">
                <MdAppSettingsAlt size={90} />
                <h3 class="text-center component-title">Scanner</h3>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 d-flex flex-column justify-content-center align-items-center">
                <MdInvertColors size={90} />
                <h3 class="text-center component-title">Test Pintura</h3>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 d-flex flex-column justify-content-center align-items-center">
                <FaCarCrash size={90} />
                <h3 class="text-center component-title">Verificar Choques</h3>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 offset-md-2 d-flex flex-column justify-content-center align-items-center">
                <GiLockpicks size={90} />
                <h3 class="text-center component-title">Revisión de componentes</h3>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 d-flex flex-column justify-content-center align-items-center">
                <RiSteering2Fill size={90} />
                <h3 class="text-center component-title">Prueba de conducción</h3>
              </div>
              <a href="https://reportes.diagnosticar.cl/?q=f7e414e2-5695-11ef-85ba-506508fbd126" target="_blank" class="bg-transparent col-xs-12 col-sm-6 col-md-4 offset-md-4 d-flex flex-column justify-content-center align-items-center">
                <MdFullscreen className="rounded" style={{ backgroundColor: "#212529" }} color="white" size={90} />
                <h3 class="text-center component-title text-black">Ver informe completo</h3>
              </a>
            </div>
            <hr class="m-0" />
            <div id="funcionamiento" class="row">
              <div class="col-12">
                <div class="titlepage">
                  <h2>Como funciona</h2>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 margin_bott">
                <div class="work_box">
                  <figure><img src="images/work_img1.jpg" alt="#" /></figure>
                  <div id="color_chang" class="make">
                    <h3>Agendar una <br /> Cita</h3>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6 margin_bott">
                <div class="work_box">
                  <figure><img src="images/work_img3.jpg" alt="#" /></figure>
                  <div id="color_chang" class="make">
                    <h3>Encuentro <br /> a domicilio</h3>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="work_box">
                  <figure><img src="images/work_img2.jpg" alt="#" /></figure>
                  <div id="color_chang" class="make">
                    <h3>Entrega de  <br /> Informe</h3>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="work_box">
                  <figure><img src="images/work_img4.jpg" alt="#" /></figure>
                  <div id="color_chang" class="make">
                    <h3>Tomar decisión <br /> DE COMPRA</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="contacto" class="choose">
        <div class="choose_bg">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="titlepage">
                  <h2 class="text-white">Contacto</h2>
                </div>
              </div>
              <div class="col-12 offset-md-1 col-md-3">
                <div style={{height: "100%"}} class="d-flex flex-column justify-content-center align-content-center">
                  <div class="d-flex flex-column">
                    <h4 class="text-white"><b>Horario</b></h4>
                    <h5 class="text-white">Lunes - Sabado: 09:00 - 18:00</h5>
                  </div>
                  <div class="d-flex flex-column">
                    <h4 class="text-white "><b>Telefono</b></h4>
                    <h5>
                      <a class="text-white" target="_blank" href="wa.me/56944627466">+56 9 4462 7466</a>
                    </h5>
                  </div>
                  <div class="d-flex flex-column">
                    <h4 class="text-white "><b>Email</b></h4>
                    <h5>
                      <a href="mailto:administracion@diagnosticar.cl" target="_blank" class="text-white">administracion@diagnosticar.cl</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer">
        <div class="copyright">
          <div class="container">
            <p class="m-0">Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
